import { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import path from 'path';

import blueWhale from 'assets/HODLRanks/Rank/blue-whale.svg';
import crab from 'assets/HODLRanks/Rank/crab.svg';
import dolphin from 'assets/HODLRanks/Rank/dolphin.svg';
import humpbackWhale from 'assets/HODLRanks/Rank/humpback-whale.svg';
import killerWhale from 'assets/HODLRanks/Rank/killer-whale.svg';
import kraken from 'assets/HODLRanks/Rank/kraken.svg';
import megalodon from 'assets/HODLRanks/Rank/megalodon.svg';
import octopus from 'assets/HODLRanks/Rank/octopus.svg';
import shark from 'assets/HODLRanks/Rank/shark.svg';
import shrimp from 'assets/HODLRanks/Rank/shrimp.svg';
import swordfish from 'assets/HODLRanks/Rank/swordfish.svg';
import tunaFish from 'assets/HODLRanks/Rank/tuna-fish.svg';

import vars from 'variables';

let VERSIONS;

if (typeof localStorage !== 'undefined') {
  VERSIONS = JSON.parse(localStorage.getItem('versions') || 'null');

  fetch(`${vars.apiHost}/v2/-/versions`)
    .then((response) => response.json())
    .then((versions) => {
      VERSIONS = versions;
      localStorage.setItem('versions', JSON.stringify(versions));
    });
}

const YEAR_SECONDS = 60 * 60 * 24 * 365;
const MILLION = 1000 * 1000;
const BILLION = MILLION * 1000;
const TRILLION = BILLION * 1000;

const formatNumber = (number, scale) => {
  if (typeof scale === 'undefined') {
    scale = 0;
  }

  const digits = new Intl.NumberFormat('en-US').format(number).split('.')[0];
  const decimals = Number(number).toFixed(scale).split('.')[1];

  return [digits, decimals].filter((d) => d).join('.');
};

const formatHODL = (amount, asIs, decimals = 0) => {
  let postfix = '';

  if (!asIs) {
    if (amount < BILLION) {
      amount = (amount / MILLION).toFixed(3);
      postfix = 'M';
    } else if (amount < TRILLION) {
      amount = (amount / BILLION).toFixed(3);
      postfix = 'B';
    } else {
      amount = (amount / TRILLION).toFixed(3);
      postfix = 'T';
    }
  }

  return formatNumber(amount, decimals) + postfix;
};

const toResourceURL = (base) => {
  return ({ slug }) => path.join(base, slug);
};

const useSiteData = () => {
  let {
    siteData: { pages, resources },
  } = useStaticQuery(
    graphql`
      query {
        siteData {
          pages
          resources
        }
      }
    `
  );

  return { pages: JSON.parse(pages), resources: JSON.parse(resources) };
};

const useStateEffect = (func, deps) => {
  useEffect(() => {
    let isMounted = true;
    func((prop) => {
      return (value) => {
        if (isMounted) {
          prop.set(value);
        }
      };
    });
    return () => {
      isMounted = false;
    };
  }, deps);
};

const ranks = [
  {
    name: 'Shrimp',
    level: 1,
    image: shrimp,
    thresholdAmount: 100000,
    thresholdFactor: 1,
  },
  {
    name: 'Crab',
    level: 2,
    image: crab,
    thresholdAmount: 250000,
    thresholdFactor: 1,
  },
  {
    name: 'Octopus',
    level: 3,
    image: octopus,
    thresholdAmount: 500000,
    thresholdFactor: 1,
  },
  {
    name: 'Tuna Fish',
    level: 4,
    image: tunaFish,
    thresholdAmount: 1,
    thresholdFactor: MILLION,
  },
  {
    name: 'Swordfish',
    level: 5,
    image: swordfish,
    thresholdAmount: 2.5,
    thresholdFactor: MILLION,
  },
  {
    name: 'Dolphin',
    level: 6,
    image: dolphin,
    thresholdAmount: 5,
    thresholdFactor: MILLION,
  },
  {
    name: 'Shark',
    level: 7,
    image: shark,
    thresholdAmount: 10,
    thresholdFactor: MILLION,
  },
  {
    name: 'Killer Whale',
    level: 8,
    image: killerWhale,
    thresholdAmount: 50,
    thresholdFactor: MILLION,
  },
  {
    name: 'Humpback Whale',
    level: 9,
    image: humpbackWhale,
    thresholdAmount: 100,
    thresholdFactor: MILLION,
  },
  {
    name: 'Blue Whale',
    level: 10,
    image: blueWhale,
    thresholdAmount: 200,
    thresholdFactor: MILLION,
  },
  {
    name: 'Kraken',
    level: 11,
    image: kraken,
    thresholdAmount: 300,
    thresholdFactor: MILLION,
  },
  {
    name: 'Megalodon',
    level: 12,
    image: megalodon,
    thresholdAmount: 1,
    thresholdFactor: BILLION,
  },
  {
    name: 'Thanos',
    level: 13,
    image: megalodon,
    thresholdAmount: 10,
    thresholdFactor: BILLION,
  },
];

const factors = {
  [1]: '',
  [MILLION]: 'million',
  [BILLION]: 'billion',
  [TRILLION]: 'trillion',
};

const rankIndex = (balance) =>
  ranks.findIndex(
    ({ thresholdAmount, thresholdFactor }) =>
      balance < thresholdAmount * thresholdFactor
  );

const prevRank = (balance) => ranks[rankIndex(balance) - 1] || {};
const currentRank = (balance) => ranks[rankIndex(balance)] || {};
const nextRank = (balance) => ranks[rankIndex(balance) + 1];
const nextRankTarget = (balance) => {
  const rank = currentRank(balance);
  return rank
    ? `${rank.thresholdAmount} ${factors[rank.thresholdFactor]}`
    : null;
};

const localStorageVersion = (key) => {
  return (
    {
      bscData: 1,
      dashboardData: 1,
      history: 1,
      provider: 1,
      walletData: 2,
    }[key] +
    '.' +
    ((VERSIONS || {})[key] || 0)
  );
};

const localStorageGet = (key, defaultValue) => {
  try {
    const data = JSON.parse(localStorage.getItem(key) || '{}');
    if (data.version === localStorageVersion(key)) {
      return data.data;
    } else {
      localStorage.removeItem(key);
      return defaultValue;
    }
  } catch (error) {
    return defaultValue;
  }
};

const localStorageSet = (key, data) => {
  localStorage.setItem(
    key,
    JSON.stringify({
      version: localStorageVersion(key),
      data: data,
    })
  );
};

const inWindow = () => typeof window !== 'undefined';

export {
  YEAR_SECONDS,
  currentRank,
  formatHODL,
  formatNumber,
  inWindow,
  localStorageGet,
  localStorageSet,
  nextRank,
  nextRankTarget,
  prevRank,
  toResourceURL,
  useSiteData,
  useStateEffect,
};
